.conversejs {

    #chatrooms {
        .muc-domain-group-toggle {
            margin: 0.75em 0 0.25em 0;
        }

        .muc-domain-group-toggle, .muc-domain-group-toggle .fa {
            color: var(--groupchats-header-color);
            &:hover {
                color: var(--chatroom-head-bg-color-dark);
            }
        }

        converse-rooms-list {
            display: block;
            margin-bottom: 0.5em;

            .list-item {
                padding-top: 0;
                padding-bottom: 0;

                .open-room {
                    display: flex;
                    flex-direction: row;
                    span {
                        padding-top: 0.5em;
                    }
                }
            }
        }
    }
}
